// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
// @import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
@import url('../src/assets/less/scroll.less');
@import url('../src/assets/less/main-structure.less');
@import url('../src/assets/less/scheme/background.less');
@import url('../src/assets/less/scheme/color.less');
@import url('../src/assets/less/scheme/view-port.less');
@import url('../src/assets/less/uni-value/font-size.less');

:root {
    --mat-table-row-item-label-text-size: 15px;
    --mat-option-selected-state-label-text-color: white;
    --mat-option-selected-state-layer-color: #89898e;
    --mat-select-focused-arrow-color: @main_yellColor[_main];
    --mdc-outlined-text-field-focus-outline-color: @main_yellColor[_main];
    --mdc-outlined-text-field-focus-label-text-color: #666666;
    --mdc-outlined-text-field-caret-color: @main_yellColor[_main];
    --mdc-filled-text-field-caret-color: @main_yellColor[_main];

    --mdc-filled-text-field-focus-label-text-color: #666666;
    --mdc-outlined-text-field-hover-outline-color: @main_yellColor[_main];
    --mdc-filled-text-field-focus-active-indicator-color: #f0bd12;
    --mat-form-field-container-height: 40px;
    --mat-form-field-container-vertical-padding: 10px;

    @media @W1440 {
        --mat-table-row-item-label-text-size: 16px;
    }

    @media @W1680 {
        --mat-table-row-item-label-text-size: 1.04rem;
    }
}

html,
body {
    height: 100%;
}

body {
    .greyScroll();
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    //font-size: 16px;

    &.fixed-scroll {
        overflow: hidden !important;
    }

    .uniFontSize();
}

a {
    color: @hyperLinkColor[_main];
    text-decoration: none;

    &:hover {
        color: @hyperLinkColor[_hover];
    }
}

.asnt-bg-marble {
    .asnt-bg-marble();
}


.mat-mdc-form-field {
    .mdc-text-field--filled {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        transition: linear 0.2s;

        &:hover {
            .mdc-line-ripple {
                &::before {
                    border-color: transparent !important;

                }
            }
        }

        &:not(.mdc-text-field--disabled) {
            //background-color: white !important;
        }

        &.mdc-text-field--focused {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    &.hasShadow {
        .mdc-text-field--filled {
            &:not(.mdc-text-field--disabled) {
                box-shadow: rgba(0, 0, 0, 0.2) 0 0 15px 5px;
            }
        }

    }
}

.mat-icon {
    font-weight: 100 !important;
}
