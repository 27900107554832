@scrollSize_main: 7px;
@scrollSize_lg  : 10px;

/* Scroll  ===============================================*/


.greyScroll() {
  border: solid 0 transparent;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width           : @scrollSize_main;
    background-color: #EEE;
  }


  &::-webkit-scrollbar:horizontal {
    height          : @scrollSize_main;
    background-color: #EEE;
  }


  &::-webkit-scrollbar-thumb {
    background-color: #878c92;
  }

  &::-webkit-scrollbar-thumb:vertical:hover {
    background-color: #75797e;
  }

}

//卷軸軌道透明
.scrollTransparent() {

  &::-webkit-scrollbar:vertical,
  &::-webkit-scrollbar:horizontal {
    background-color: transparent !important;
  }
}

//圓角卷軸
.radiusScroll() {

  &::-webkit-scrollbar-thumb {
    border-radius: 99em !important;
  }

}


cdk-virtual-scroll-viewport {
  .greyScroll();
}


//轉軸尺寸
.scrollSize_MD() {
  &::-webkit-scrollbar:vertical {
    width: @scrollSize_lg;
  }

  &::-webkit-scrollbar:horizontal {
    height: @scrollSize_lg;
  }
}
