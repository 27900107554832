@import url('../scheme/view-port.less');


@uniFontSize : 1rem;
@uniFontSize_2K : 1.15rem;
@uniFontSize_maxVw : 13px;
@breadcrumbFontSize: 15px;


.uniFontSize() {

  .elemFontSize(15px);


  @media @W370 {
    .elemFontSize(13px);
  }

  @media @W1024 {
    .elemFontSize(13px);
  }

  @media @W1440 {
    .elemFontSize(16px);
  }

  @media @W2100 {
    .elemFontSize(17px);
  }

  @media @W360max {
    .elemFontSize(12px) !important;
  }




}

.elemFontSize(@size) {

  td,
  th,
  li,
  p,
  a,
  .sub,
  .val,
  .itm,
  .list-cell,
  .descp,
  .asnt-table-cell,
  .asnt-nav-item,
  .topDescription,
  .asnt-carousel-tab-mobile,
  .asnt-table-mobile-list-view,
  .asnt-table-mobile-value,
  .asnt-table-mobile-header-item,
  .asnt-breadcrumb-span,
  .about-organization-item-name {
    font-size: @size;
  }
}